require.config({
    baseUrl: '/js/app',
    paths: {
        globals: "_globals",
        text: "/lib/requirejs/text",
        json: "/lib/requirejs/json"
    },
    shim: {}
});

const culture = $.cookie('.AspNetCore.Culture') || 'c=en|uic=en'; // Append current culture to localization path to avoid caching issues
var requiredFiles = ["json!config.json", "json!/WebServices/Localization?" + culture, "AppMain", "globals"];

// load AMD module AppMain.ts (compiled to AppMain.js)
require(requiredFiles, (configFile: string, localization: any, AppMain: any) => {

    var config = configFile;

    // Jquery Erweiterungen
    $.extend({
        getString: (key: string) => {
            if (localization != null && localization[key]) {
                return localization[key];
            }
            return key;
        },
        isDate: (date: any) => {
            return date instanceof Date && !isNaN(date.valueOf());
        },
        selectText: (elem: HTMLElement) => {
            if (window.getSelection) {
                var selection = window.getSelection();
                var range = document.createRange();
                range.selectNodeContents(elem);
                selection.removeAllRanges();
                selection.addRange(range);
            }
        },
        getStyleSheet: (url) => {
            var head = document.getElementsByTagName("head")[0];
            var link = document.createElement("link");
            link.setAttribute("rel", "stylesheet");
            link.type = "text/css";
            link.href = url;

            head.appendChild(link);
        },
        indexOfElement: (array: Array<any>, key: string, value: string | number) => {
            for (let i = 0; i < array.length; i++) {
                if (array[i].hasOwnProperty(key) && array[i][key] === value)
                    return i;
            }
            return -1;
        },
        convertChartLabel: (level: string, labelString: string) => {
            const ceLevelString = $.getString('CeLevel');
            if (labelString.indexOf('Composite') >= 0) {
                return labelString
                    .replace('Composite', $.getString('BatteryLifeComposite'))
                    .replace('nb-iot', $.getString('WizardAccessTechnologyNbIot'))
                    .replace('lte-m', $.getString('WizardAccessTechnologyLteM'));              
            } else if (labelString.indexOf('CE') >= 0) {               
                return labelString.replace('CE', ceLevelString + " ");               
            } else if (labelString.indexOf('Coverage') >= 0) {               
                return labelString.replace(level, $.getString(level));
            }
            return labelString;
        },
        convertChartLabelForOptimize: (label: string, forOptimize: boolean) => {
            const ceLevelString = $.getString('CeLevel');
            if (label.indexOf('Composite') >= 0) {
                if (forOptimize) {
                    return label.replace('Composite', $.getString('BatteryLifeComposite') + "<br/>");
                } else {
                    return label.replace('Composite', $.getString('BatteryLifeComposite'));
                }
            } else if (label.indexOf('DeepIndoor') >= 0) {
                if (forOptimize) {
                    //return label.replace('DeepIndoor', $.getString('CoverageDataDeepIndoorText')) + "<br/>" + ceLevelString + " 2";
                    return label.replace('DeepIndoor', ceLevelString + " 2");
                } else {
                    return $.getString('CoverageDataDeepIndoorText');
                }
            } else if (label.indexOf('Indoor') >= 0 && forOptimize) {
                //return label.replace('Indoor', $.getString('CoverageDataIndoorText')) + "<br/>" + ceLevelString + " 1";
                return label.replace('Indoor', ceLevelString + " 1");
            } else if (label.indexOf('Outdoor') >= 0 && forOptimize) {
                //return label.replace('Outdoor', $.getString('CoverageDataOutdoorText')) + "<br/>" + ceLevelString + " 0";
                return label.replace('Outdoor', ceLevelString + " 0");
            }
            return label;
        },
        alert: (message: string, title: string) => {
            // dialog.custom instead of dialog.alert because OK button is not localized otherwise
            // See https://supportcenter.devexpress.com/Ticket/Details/T237273/localization-dialog-alert-ok-button-text
            DevExpress.ui.dialog.custom({
                title: title,
                messageHtml: message,
                buttons: [{
                    text: $.getString('OkButton'),
                    onClick: () => true
                }]
            }).show();
        },
        showDropdownOption: (dropdownSelector: string, value: string) => {
            $(dropdownSelector).find('option[value=' + value + ']').attr('data-visible', 'true').attr('disabled', null).show();
        },
        hideDropdownOption: (dropdownSelector: string, value: string) => {
            $(dropdownSelector).find('option[value=' + value + ']').attr('data-visible', 'false').attr('disabled', 'disabled').hide();
        },
        lockDropdownIfSingleValue: (dropdownSelector: string, $items: JQuery<HTMLElement>, hidePlaceholder = true) => {
            if ($items.length === 1) {
                const value = $items.val();
                if (hidePlaceholder) $.hideDropdownOption(this.dropdownSelector, '-1');
                $(dropdownSelector).val(value);
                $(dropdownSelector).attr('readonly', 'readonly');
                $(dropdownSelector).addClass('single-value');
            } else {
                $(dropdownSelector).removeAttr('readonly');
                $(dropdownSelector).removeClass('single-value');
                if (!$(dropdownSelector).val()) {
                    $.showDropdownOption(dropdownSelector, '-1');
                    $(dropdownSelector).val('-1');
                }
            }
        }       
    });
    
    const app = new AppMain();
    app.run(config);
});

// an another way to load css dynamicaly
function loadCSSFile(url) {
    "use strict";
    const ss = window.document.createElement("link");
    const ref = window.document.getElementsByTagName("script")[0];
    ss.rel = "stylesheet";
    ss.href = url;
    // temporarily, set media to something non-matching to ensure it'll fetch without blocking render
    ss.media = "only x";
    // inject link
    ref.parentNode.insertBefore(ss, ref);
    // set media back to `all` so that the styleshet applies once it loads
    setTimeout(function () {
        ss.media = "all";
    });
}